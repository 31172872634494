<template>
  <div
    class="preloader-container"
    :class="{
      preloader: preloaderClass
    }"
  >
    <Preloader
      :visible="preloader"
      class="preloader-container__animate"
    />

    <slot name="content" />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

import Preloader from '@/ui/preloader/SkeletonAnimatePreloader.vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  preloader: {
    type: Boolean,
    default: true
  }
})

const preloaderClass = ref(true)

watch(() => props.preloader, () => {
  preloaderClass.value = true

  setTimeout(() => {
    preloaderClass.value = false
  }, 2000)
})
</script>

<style lang="scss" scoped>
.preloader-container {
  position: relative;
  background: white;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
  border-radius: 15px;

  &.preloader {
    overflow: hidden !important;
  }

  &__animate {
    border-radius: inherit;
    overflow: hidden;
  }
}
</style>
