<template>
  <Container
    :preloader="preloader"
    class="container"
  >
    <template #content>
      <h2>
        {{ $t('views.auth.sign-up-verify.title') }}
      </h2>

      <p>
        {{ $t('views.auth.sign-up-verify.text') }}
      </p>

      <p>
        {{ $t('views.auth.sign-up-verify.phone-text') }} +{{ value.phone }}
      </p>

      <BlueButton
        :text="$t('views.auth.sign-up-verify.send-code')"
        :loading="loading"
        :disabled="loading"
        @click="sendCode()"
      />

      <RouterLink
        :to="{ name: 'SignUpVerifyPhoneEdit' }"
        class="container__link"
      >
        {{ $t('views.auth.sign-up-verify.phone-edit') }}
      </RouterLink>
    </template>
  </Container>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

const $route = useRoute()
const $router = useRouter()
const app = getCurrentInstance()
const { appContext: { config: { globalProperties: { $axios } } } } = app

const preloader = ref(true)
const value = ref({ status: false })
const loading = ref(false)

async function fetch () {
  try {
    preloader.value = true

    const { data } = await $axios.get(`auth/get-phone-by-hash/${$route.params.hash}`)

    if (data.status) {
      value.value = data
    }
  } catch (err) {
    throw new Error(err)
  } finally {
    preloader.value = false
  }
}

async function sendCode () {
  try {
    loading.value = true

    await $axios.post(`auth/sens-sms-by-hash/${$route.params.hash}`)
  } catch (err) {
    throw new Error(err)
  } finally {
    $router.push({ name: 'SignUpVerifyPhoneCode' })
  }
}

onMounted(() => {
  fetch()
})
</script>

<style lang="scss" scoped>
.container {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    font-size: 32px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
  }

  p {
    font-size: 14px;
    line-height: 120%;
    color: #787C8D;
    font-family: Medium;
  }

  &__link {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    color: rgb(47, 87, 233);
    font-family: SemiBold;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    text-decoration: none;
  }
}
</style>
